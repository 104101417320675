import CookieConsent from '@grrr/cookie-consent'
import Cookies from 'js-cookie'

(() => {
  const isAccepted = 'cl_accepted'
  const cookieConsentObj = 'cookie-consent-preferences'
  const skipDeleteCookies = [isAccepted, 'lang']
  const url = `${window.location.origin}/cookies.json`
  const manageCookies = 'manageCookies'
  let isVisible = window.cookieConsentVisible
  let initRun = true

  const getCookie = (name) => {
    return Cookies.get(name) || false
  }

  const deleteCookie = (name) => {
    const domain = window.location.hostname.replace('www', '')
    const path = '/'
    const date = 'Sat, 26 Oct 1985 00:09:00 GMT'
    document.cookie = `${name}=; Path=${path}; Domain=${domain}; Expires=${date};`
  }

  const setCookieConsent = (response) => {
    let newCookies = []
    response.settings.data.map((i) => {
      let cookie = {
        description: i.description,
        id: i.id,
        label: i.label,
        required: i.required,
      }
      newCookies.push(cookie)

    })
    return newCookies
  }

  const setLabels = (response) => {
    let labels = {}
    response.settings.translations.map((i) => {
      labels = {
        button: {
          default: i.button,
        },
        description: `<p>${i.description}</p>`,
        title: i.title,
      }

    })
    return labels
  }

  const sendCookies = async (acceptedCookies) => {
    const response = await fetch(url,
      {
        body: JSON.stringify(acceptedCookies),
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
      })

    await response.json()
    if (!acceptedCookies[1].accepted) {
      for (let cookie in Cookies.get()) {
        if (!skipDeleteCookies.includes(cookie)) {
          deleteCookie(cookie)
        }
      }
      if (!initRun) {
        window.location.reload()
      }
    }
    initRun = false
  }

  const appendManageCookiesTab = (t) => {
    const cookieTabList = document.querySelector('.cookie-consent__tab-list')
    const manageCookiesTab = `<div id="${manageCookies}" class="cookie-consent__tab-toggle cookie-consent__manage-tab" aria-selected="false">${t.manage_cookies}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 16"><path d="M21.5.5l3 3.057-12 11.943L.5 3.557 3.5.5l9 9z"></path></svg>
                         </div>`

    cookieTabList.insertAdjacentHTML('beforebegin', manageCookiesTab)
  }

  const setManageCookiesState = (isOpen, t) => {
    const manageCookiesTab = document.getElementById(manageCookies),
      cookieButton = document.querySelector('.cookie-consent__button')

    if (manageCookiesTab) {
      manageCookiesTab.setAttribute('aria-selected', isOpen ? 'true' : 'false')
      cookieButton.innerHTML = isOpen ? t.button_save : t.button
    }
  }

  const clickListener = (t) => {
    document.addEventListener('click', function (e) {
      const isManageCookieOpen = document.getElementById(manageCookies).getAttribute('aria-selected')

      if (e.target.id === manageCookies) {
        setManageCookiesState(isManageCookieOpen === 'false', t)
      }
      if (e.target.className === 'cookie-consent__button' && isManageCookieOpen === 'false') {
        document.querySelector('input[value="marketing"]').checked = true
      }
    })
  }

  const cookieConsentInit = async () => {
    if (!getCookie(isAccepted)) {
      localStorage.removeItem(cookieConsentObj)
    }

    const response = await fetch(url)
    const data = await response.json()
    const translations = data.settings.translations[0]
    const cookieConsent = CookieConsent({
      cookies: setCookieConsent(data),
      labels: setLabels(data),
    })

    if (!isVisible) {
      // By default, the appendDelay option equals 500
      setTimeout(() => cookieConsent.hideDialog(), 501)
      isVisible = true
    }

    appendManageCookiesTab(translations)
    window.cookieConsentInit = cookieConsent.showDialog
    cookieConsent.on('update', () => {
      sendCookies(cookieConsent.getPreferences())
      setManageCookiesState(false, translations)
    })

    clickListener(translations)
  }

  cookieConsentInit()
})()
